body {
  background-color: black;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

p,
li {
  font-size: 18px;
}

#toggle-2d-3d {
  position: absolute;
  left: 10px;
}

a {
  color: white;
  text-decoration: none;
}

.fake-button {
  padding: 20px 30px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #054569;
  font-family: "Orbitron", sans-serif;
  font-size: 16px;
}

.fake-button:hover {
  cursor: pointer;
  background-color: #5591a9;
}

#instructions-3d {
  text-align: center;
  width: 20vw;
  padding: 10px 10vw;
  position: absolute;
  left: 30vw;
  top: 100px;
  background-color: rgb(0, 0, 0);
  border: 5px solid #5591a9;
  border-radius: 10px;
}

.instructions-panel {
  display: grid;
  position: absolute;
  right: 10px;
  bottom: 10px;
  grid-template-columns: 25px 25px 25px 170px;
  grid-template-rows: 25px 25px 5px 40px;
  grid-template-areas:
    ". up-arrow . move-rocket"
    "left-arrow down-arrow right-arrow move-rocket"
    ". . . . "
    "move-camera move-camera move-camera move-camera";
  padding: 20px 30px;
  margin-right: 5px;
  margin-left: 5px;
  background-color: #054569;
  font-family: "Orbitron", sans-serif;
  font-size: 16px;
}

#up-arrow {
  margin: 0px;
  place-self: center;
  grid-area: up-arrow;
  height: 23px;
  width: 23px;
  text-align: center;
  background-color: #5591a9;
}

#left-arrow {
  margin: 0px;
  place-self: center;
  grid-area: left-arrow;
  height: 23px;
  width: 23px;
  text-align: center;
  background-color: #5591a9;
}

#down-arrow {
  margin: 0px;
  place-self: center;
  grid-area: down-arrow;
  height: 23px;
  width: 23px;
  text-align: center;
  background-color: #5591a9;
}

#right-arrow {
  margin: 0px;
  place-self: center;
  grid-area: right-arrow;
  height: 23px;
  width: 23px;
  text-align: center;
  background-color: #5591a9;
}

#move-rocket {
  margin: 0px;
  place-self: center;
  grid-area: move-rocket;
}

#move-camera {
  margin: 0px;
  place-self: center;
  grid-area: move-camera;
}

.P5Component {
  display: flex;
  height: 97vh;
  justify-content: center;
  align-items: flex-end;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-button {
  position: absolute;
  left: 10px;
}

h1,
h2 {
  color: #9ccddc;
  /* text-shadow: 0px 0px 4px white; */
  font-family: "Orbitron", sans-serif;
  margin-bottom: 0px;
}

h1 {
  font-size: 72px;
  text-align: center;
}

h2 {
  font-size: 50px;
}

h3 {
  font-family: "orbitron", sans-serif;
}

h3.project-tagline {
  text-align: center;
}

.project .nav-bar {
  display: flex;
  flex-direction: row;
}

.project .project-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 50px;
  background: rgba(6, 44, 67, 0.5);
  /* background-color: #062c43; */
  /* color: #ced7e0; */
}

.project .loom-div {
  padding-bottom: 20px;
  width: inherit;
  height: 45.6vw;
}

.project .loom-iframe {
  width: inherit;
  height: inherit;
}

#home-2d {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-2d-projects,
.home-2d-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
